<template>
  <div class="container">
    <div class="content">
      <field label="模版名称：" :asterisk="true" labelWidth="180px" :validation="validation.name" >
        <el-input v-model="formData.name" placeholder="请输入模版名称" style="width: 640px"></el-input>
      </field>
      <field label="类型：" :asterisk="true" labelWidth="180px" :validation="validation.type" >
        <el-radio-group v-model="formData.type" style="'test-align': left">
          <!-- <el-radio label="QUANTITY">件数</el-radio> -->
          <el-radio label="WEIGHT">重量</el-radio>
        </el-radio-group>
      </field>
      <field label="配送区域：" :asterisk="true" labelWidth="180px" :validation="validation.items" >
        <div>
          <div class="area">
            <div class="area-grid">
              <div class="area-title"></div>
              <div class="area-title">可配送区域</div>
              <div class="area-title">首件（{{ formData.type === 'QUANTITY' ? '件' : '克' }}）</div>
              <div class="area-title">运费（元）</div>
              <div class="area-title">续件（{{ formData.type === 'QUANTITY' ? '件' : '克' }}）</div>
              <div class="area-title">续费（元）</div>
              <div class="area-title"></div>
            </div>
            <div
              class="area-grid"
              v-for="(item, idx) in formData.items"
              :key="idx"
            >
              <div class="area-item" :style="{background: idx % 2 === 0 ? '#FFFFFF' : '#F8F8F8'}"></div>
              <div class="area-item" :style="{background: idx % 2 === 0 ? '#FFFFFF' : '#F8F8F8'}">
                <el-select v-model="item.provinces" placeholder="请选择省份" style="width: 160px" multiple collapse-tags>
                  <el-option
                    v-for="item in provinces"
                    :key="item.code"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="area-item" :style="{background: idx % 2 === 0 ? '#FFFFFF' : '#F8F8F8'}">
                <el-input-number v-model="item.firstQuantity" :controls="false" :min="1" style="width: 100px"></el-input-number>
              </div>
              <div class="area-item" :style="{background: idx % 2 === 0 ? '#FFFFFF' : '#F8F8F8'}">
                <el-input v-model="item.firstFreight" :controls="false" :min="1" style="width: 100px"></el-input>
              </div>
              <div class="area-item" :style="{background: idx % 2 === 0 ? '#FFFFFF' : '#F8F8F8'}">
                <el-input-number v-model="item.secQuantity" :controls="false"  :min="1" style="width: 100px"></el-input-number>
              </div>
              <div class="area-item" :style="{background: idx % 2 === 0 ? '#FFFFFF' : '#F8F8F8'}">
                <el-input v-model="item.secFreight" :controls="false" :min="1" style="width: 100px"></el-input>
              </div>
              <div class="area-item" :style="{background: idx % 2 === 0 ? '#FFFFFF' : '#F8F8F8'}"></div>
            </div>
          </div>
          <el-button type="primary" @click="addArea" style="margin-top: 20px;">添加可配送区域</el-button>
        </div>
      </field>
    </div>
    <div class="footer">
      <el-button type="primary" icon="el-icon-plus" @click="submit">保存</el-button>
      <el-button type="primary" plain @click="cancel">取消</el-button>
    </div>
  </div>
</template>

<script>
import * as v from '@/utils/validator'
import validation from '@/mixins/validation'
import * as templateService from '@/api/freightTemplates'
import * as commonService from '@/api/common'
import * as R from 'ramda'

const initItem = {
  provinces: [],
  firstQuantity: 0,
  firstFreight: 0,
  secQuantity: 0,
  secFreight: 0
}

const init = {
  name: '',
  type: 'WEIGHT',
  items: [
    initItem
  ]
}

export default {
  mixins: [validation({
    rules: {
      name: [v.required('请输入模版昵称')],
      type: [v.required('请选择类型')],
      items: []
    },
    field: 'formData'
  })],
  data () {
    return {
      formData: init,
      isEditpage: false,
      provinces: []
    }
  },
  created () {
    this.fetchProvinces()
    const id = this.$route.params.id
    if (id) {
      this.isEditpage = true
      this.fetchDetailById(id)
    }
  },
  methods: {
    async fetchProvinces () {
      try {
        const res = await commonService.fetchProvinces()
        this.provinces = res.list
      } catch (err) {
        // handle error
      }
    },
    async fetchDetailById (id) {
      try {
        const res = await templateService.detail(id)
        this.formData = {
          name: res.name,
          type: res.type,
          items: res.items.map(item => {
            item.provinces = item.provinces.map(o => o.id)
            item.firstFreight = this._pennyToYuan(item.firstFreight)
            item.secFreight = this._pennyToYuan(item.secFreight)
            return item
          })
        }
      } catch (err) {
        // handle error
      }
    },
    cancel () {
      this.$router.go(-1)
    },
    addArea () {
      const newData = R.clone(this.formData)
      newData.items.push(R.clone(initItem))
      this.formData = newData
    },
    async submit () {
      try {
        await this.$validate()
        const formData = R.clone(this.formData)
        const items = formData.items.map(item => {
          item.provinces = item.provinces.map(o => {
            const target = this.provinces.find(t => t.id === o)
            return target
          })
          item.firstFreight = this._yuanToPenny(item.firstFreight)
          item.secFreight = this._yuanToPenny(item.secFreight)
          return item
        })
        const postData = {
          name: formData.name,
          items: items,
          type: formData.type
        }
        if (this.isEditpage) {
          await templateService.update(this.$route.params.id, postData)
        } else {
          await templateService.create(postData)
        }
        this.formData = init
        this.$message.success('创建运费模版成功')
        this.$router.push({ path: '/freightTemplates' })
      } catch (err) {
        // handle err
        this.$message.error('创建运费模版失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-rows: auto 120px;
  height: 100%;
}

.content {
  padding-top: 40px;
  background: #FFFFFFFF;
  width: 100%;
}

.footer {
  line-height: 120px;;
}

.area {
  border: 1px solid #D9E0F0;
  margin-top: 12px;

  &-grid {
    display: grid;
    grid-template-columns: 30px 1fr 1fr 1fr 1fr 1fr 30px;
  }
  &-title {
    line-height: 39px;
    background: #E8F4FFFF;
  }

  &-item {
    line-height: 70px;
  }
}

</style>
